import { Users, UsersThree, UsersFour, Leaf } from "@phosphor-icons/react";

export const basicFeatures = [
  "Unlimited Retrospective",
  "Unlimited Planning Poker",
  "Free Updates",
];

export const getPriceMeta = (planName, quota) => {
  const base = {
    features: [
      `Up to ${quota} members per team`,
      "Advanced Team customisation",
      "Priority support",
    ],
    tag: `${quota} members`,
  };

  switch (planName) {
    case "small":
      return {
        icon: Users,
        colorScheme: "brand",
        ...base,
      };
    case "medium":
      return {
        icon: UsersThree,
        colorScheme: "blue",
        ...base,
      };
    case "large":
      return {
        icon: UsersFour,
        colorScheme: "orange",
        ...base,
      };

    default:
      return {
        icon: Leaf,
        colorScheme: "gray",
        ...base,
      };
  }
};
