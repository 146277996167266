import {
  Flex,
  Heading,
  Icon,
  List,
  ListIcon,
  ListItem,
  Text,
  Box,
  useColorModeValue,
  VStack,
  Tag,
} from "@chakra-ui/react";
import { Check } from "@phosphor-icons/react";

export const PricingCard = (props) => {
  const { data, icon, button, ...rest } = props;
  const { features, price, name } = data;
  const accentColor = useColorModeValue("brand.600", "brand.200");
  return (
    <Card
      rounded={{
        sm: "xl",
      }}
      {...rest}
    >
      <VStack spacing={6}>
        <Icon aria-hidden as={icon} fontSize="5xl" color={accentColor} />
        <Heading size="md" fontWeight="bold">
          {name}
        </Heading>
      </VStack>
      {data.tag && (
        <Flex mt={2} justifyContent="center">
          <Tag size="sm" letterSpacing={0.75} textTransform="uppercase">
            {data.tag}
          </Tag>
        </Flex>
      )}
      <Flex
        align="flex-end"
        justify="center"
        fontWeight="bold"
        color={accentColor}
        mt={4}
        mb={10}
      >
        <Heading size="3xl" fontWeight="inherit" lineHeight="0.9em">
          {price}
        </Heading>
        <Text fontWeight="inherit" fontSize="2xl">
          {data.period}
        </Text>
      </Flex>
      {button}
      <List whiteSpace="nowrap" spacing="4" my="8" px={4}>
        {features.map((feature, index) => (
          <ListItem fontWeight="medium" key={index}>
            <ListIcon
              fontSize="xl"
              as={Check}
              marginEnd={2}
              color={accentColor}
            />
            {feature}
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

const Card = (props) => {
  const { children, isPopular, ...rest } = props;
  return (
    <Box
      bg={useColorModeValue("white", "gray.700")}
      borderWidth={2}
      borderColor={
        isPopular ? useColorModeValue("brand.500", "brand.200") : "transparent"
      }
      position="relative"
      px="6"
      pb="6"
      pt="16"
      overflow="hidden"
      shadow="lg"
      maxW="md"
      width="100%"
      {...rest}
    >
      {isPopular && <CardBadge>Most Popular</CardBadge>}
      {children}
    </Box>
  );
};

const CardBadge = (props) => {
  const { children, ...flexProps } = props;
  return (
    <Flex
      bg={useColorModeValue("brand.500", "brand.200")}
      position="absolute"
      right={-20}
      top={6}
      width="240px"
      transform="rotate(45deg)"
      py={2}
      justifyContent="center"
      alignItems="center"
      {...flexProps}
    >
      <Text
        fontSize="xs"
        textTransform="uppercase"
        fontWeight="bold"
        letterSpacing="wider"
        color={useColorModeValue("white", "gray.800")}
      >
        {children}
      </Text>
    </Flex>
  );
};
