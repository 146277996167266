import { Button } from "@chakra-ui/react";

export const LargeButton = (props) => (
  <Button
    colorScheme="brand"
    size={{ base: "md", md: "lg" }}
    mx={2}
    w="full"
    fontWeight="bold"
    py={4}
    px={4}
    {...props}
  />
);
