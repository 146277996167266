import {
  Box,
  Button,
  ButtonGroup,
  Text,
  Flex,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { LargeButton } from "@components/LargeButton";
import { PricingCard } from "./PricingCard";
import Link from "next/link";
import { default as ChakraLink } from "@components/Link";
import { useState } from "react";
import Image from "next/image";
import { logEvent } from "firebase/analytics";
import { analytics } from "lib/firebase";
import { basicFeatures, getPriceMeta } from "@lib/plans";

export const PricingSection = ({ plans }) => {
  const [showYearly, setShowYearly] = useState(false);

  const dataPlans = plans
    .sort((a, b) => b.price - a.price)
    .filter((plan) =>
      showYearly ? plan.period === "yearly" : plan.period === "monthly"
    );

  const helperText = useColorModeValue("gray.700", "gray.300");

  return (
    <Box
      as="section"
      bg={useColorModeValue("gray.50", "gray.800")}
      py="14"
      px={{
        base: "4",
        md: "8",
      }}
    >
      <ButtonGroup
        colorScheme="gray"
        mb={12}
        display="flex"
        justifyContent="center"
      >
        <Button
          variant={showYearly ? "ghost" : "solid"}
          onClick={() => setShowYearly(false)}
          _focus={{ boxShadow: "none" }}
        >
          Monthly
        </Button>
        <Button
          variant={showYearly ? "solid" : "ghost"}
          onClick={() => setShowYearly(true)}
          _focus={{ boxShadow: "none" }}
        >
          Yearly
        </Button>
      </ButtonGroup>

      <SimpleGrid
        columns={{
          base: 1,
          lg: 3,
        }}
        spacing={{
          base: "8",
          lg: "0",
        }}
        maxW="7xl"
        mx="auto"
        justifyItems="center"
        alignItems="center"
      >
        {/* Free Plan - Always static. */}
        <PricingCard
          data={{
            price: "Free",
            name: "Free Team",
            features: [
              "Up to 3 members per team",
              "Unlimited Retrospective",
              "Unlimited Planning Poker",
              "Free Updates",
            ],
          }}
          icon={getPriceMeta("free", 3).icon}
          button={
            <Link href="/register">
              <LargeButton
                variant="outline"
                borderWidth="2px"
                onClick={() => {
                  logEvent(analytics, "clicked_create_account_from_plan", {
                    plan: "free",
                  });
                }}
              >
                Create account
              </LargeButton>
            </Link>
          }
        />

        {/* Plans fetched from Firestore */}
        {dataPlans.map((plan, i) => (
          <PricingCard
            key={i}
            zIndex={i === 0 ? 1 : 0}
            isPopular={i === 0}
            transform={{
              lg: i === 0 ? "scale(1.025)" : "scale(1)",
            }}
            data={{
              price: `$${plan.price}`,
              period: plan.period === "monthly" ? "/mo" : "/yr",
              name: plan.title,
              tag: getPriceMeta(plan.planName, plan.quota).tag,
              features: [
                ...basicFeatures,
                ...getPriceMeta(plan.planName, plan.quota).features,
              ],
            }}
            icon={getPriceMeta(plan.planName, plan.quota).icon}
            button={
              <Link href="/register">
                <LargeButton
                  onClick={() => {
                    logEvent(analytics, "clicked_create_account_from_plan", {
                      plan: `${plan.planName}-${plan.period}`,
                    });
                  }}
                  variant={i === 0 ? "solid" : "outline"}
                  borderWidth="2px"
                >
                  Create account
                </LargeButton>
              </Link>
            }
          />
        ))}
      </SimpleGrid>

      <Flex
        textAlign="center"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="center"
        mt={12}
      >
        <Text>Need a custom package? </Text>
        <ChakraLink textDecoration="underline" mx={2} href="/contact">
          Send us an email
        </ChakraLink>
        <Text>and we'll get back to you within 24 hours.</Text>
      </Flex>

      <Text fontSize="sm" color={helperText} mt={4} textAlign="center">
        Pricing relates to upgrading Teams. Create an Account &gt; Create a Team
        &gt; Click "Upgrade"
      </Text>
      <Text fontSize="sm" color={helperText} textAlign="center">
        Billing is handled via Stripe Payments and billed in USD only.
      </Text>

      <StripeClimate />

      <PaymentMethods />
    </Box>
  );
};

export const PaymentMethods = (props) => {
  return (
    <Box mt={4} display="flex" justifyContent="center" {...props}>
      <Image
        width="314.68px"
        height="27.22px"
        src="/img/payment_methods.svg"
        alt="Payment methods available: Stripe, Google Pay, Apple Pay, Visa, Mastercard, Amex, Discover and many more."
      />
    </Box>
  );
};

export const StripeClimate = (props) => {
  return (
    <Flex
      mt={4}
      flexDirection={{ base: "column", md: "row" }}
      textAlign={{ base: "center", md: "left" }}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Image
        width="20px"
        height="20px"
        src="/img/stripe_climate.svg"
        alt="Stripe Climate Badge"
      />

      <Text mt={{ base: 2, md: 0 }} fontSize="sm" ml={2}>
        We donate 2% of your payment towards{" "}
        <ChakraLink
          rel="noopener"
          href="https://stripe.com/climate"
          target="_blank"
        >
          CO₂ removal.
        </ChakraLink>
      </Text>
    </Flex>
  );
};
